<template>
  <UiContainer
    :no-padding="true"
    class="bg-navy text-white"
  >
    <footer class="my-12 py-6">
      <div class="container mx-auto py-4 xl:px-0">
        <div class="flex w-full flex-col md:flex-row md:space-x-8">
          <div class="md:w-1/2">
            <a
              href="/"
              class="block w-40 text-nowrap font-bold text-white"
            >
              <NuxtIcon
                name="ussif:logo"
                class="size-full text-white"
              />
            </a>
            <div class="flex w-full flex-row md:space-x-8">
              <div class="w-1/2 py-8 md:w-1/3">
                <h4 class="text-xl font-bold opacity-40">
                  US SIF
                </h4>
                <nav class="mt-6 flex flex-col">
                  <a
                    href="/about"
                    class="my-2"
                  >About US SIF</a>
                  <a
                    href="/about/our-team"
                    class="my-2"
                  >Our Team</a>
                  <a
                    href="https://www.ussif.org/annualreports"
                    class="my-2"
                  >Annual Reports</a>
                  <a
                    href="https://www.ussif.org/join"
                    class="my-2"
                  >Membership Terms</a>
                  <a
                    href="https://www.ussif.org/contact"
                    class="my-2"
                  >Contact Us</a>
                </nav>
              </div>
              <div class="w-1/2 pt-8 md:w-1/3">
                <h4 class="text-xl font-bold opacity-40">
                  News
                </h4>
                <nav class="mt-6 flex flex-col">
                  <a
                    href="/news"
                    class="my-2"
                  >All News</a>
                  <a
                    href="https://www.ussif.org/articles"
                    class="my-2"
                  >Blogs</a>
                  <a
                    href="/news/press-release"
                    class="my-2"
                  >Press Releases</a>
                  <a
                    href="/news"
                    class="my-2"
                  >US SIF in the News</a>
                </nav>
              </div>
            </div>
          </div>

          <form
            class="mb-8 flex justify-end md:w-1/2"
            name="subscribe"
            netlify
          >
            <div
              class="h-fit w-full bg-white/5 p-12 sm:w-[530px]"
            >
              <h4 class="text-4xl font-bold max-sm:leading-10 lg:text-[2.375rem]">
                Subscribe to our newsletter
              </h4>
              <p class="mb-10 mt-5 text-tablet-xl leading-snug">
                Get the latest sustainable investment news and updates from
                US SIF.
              </p>

              <div class="relative mb-2">
                <input
                  id="email-subscribe"
                  type="email"
                  name="email"
                  required
                  placeholder="Enter your email"
                  class="w-full rounded-md border border-white bg-transparent p-5 pr-24 text-base text-white focus:border-blue/80"
                />
                <button
                  type="submit"
                  class="absolute right-2 top-1/2 -translate-y-1/2 rounded-md bg-blue p-3 text-white hover:bg-blue/90"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        </div>

        <div
          class="mt-4 flex flex-col items-center justify-center border-t pt-4 md:flex-row md:justify-between"
        >
          <nav class="mb-3 flex space-x-4 pr-4 md:mb-0 xl:pr-40">
            <a
              href="https://www.ussif.org/privacypolicy"
              class="text-sm"
            >Privacy Policy</a>
            <!-- <a
              href="/terms"
              class="text-xs"
            >Terms and Conditions</a>
            <a
              href="/cookies"
              class="text-xs"
            >Cookie Policy</a> -->
          </nav>
          <div class="flex flex-row items-center">
            <p class="mb-0 text-sm">
              Copyright © 2024 US SIF. All rights reserved.
            </p>
            <a
              href="https://twitter.com/US_SIF"
              class="mx-3"
            ><i class="bi bi-twitter-x" /></a>
            <a
              href="https://www.linkedin.com/company/us-sif/?viewAsMember=true"
              class="ml-3"
            ><i class="fa-brands fa-linkedin-in" /></a>
          </div>
        </div>
      </div>
    </footer>
  </UiContainer>
</template>
